@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Roboto:wght@300;400;500;700&display=swap");

*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}



.main {
	display: grid;
	grid-template-columns: 13% 87%;
	width: 100%;
	margin: 10px;
	background: rgb(254, 254, 254);

	z-index: 10;
}

.main-menu {
	overflow: hidden;
	background: rgb(73, 57, 113);
	padding-top: 10px;
	border-radius: 15px 0 0 15px;
	font-family: "Roboto", sans-serif;
}

.main-menu h1 {
	display: block;
	font-size: 1.5rem;
	font-weight: 500;
	text-align: center;
	margin: 20px 0 30px;
	color: #fff;
	font-family: "Nunito", sans-serif;
}

.logo {
	display: none;
}

.nav-item {
	position: relative;
	display: block;
}

.nav-item a {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 1rem;
	padding: 15px 0;
	margin-left: 10px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.nav-item b:nth-child(1) {
	position: absolute;
	top: -15px;
	height: 15px;
	width: 100%;
	background: #fff;
	display: none;
}

.nav-item b:nth-child(1)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	border-bottom-right-radius: 20px;
	background: rgb(73, 57, 113);
}

.nav-item b:nth-child(2) {
	position: absolute;
	bottom: -15px;
	height: 15px;
	width: 100%;
	background: #fff;
	display: none;
}

.nav-item b:nth-child(2)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-top-right-radius: 20px;
	background: rgb(73, 57, 113);
}

.nav-item.active b:nth-child(1),
.nav-item.active b:nth-child(2) {
	display: block;
}

.nav-item.active a {
	text-decoration: none;
	color: #000;
	background: rgb(254, 254, 254);
}

.nav-icon {
	width: 60px;
	height: 20px;
	font-size: 20px;
	text-align: center;
}

.nav-text {
	display: block;
	width: 120px;
	height: 20px;
	font-family: "Mulish", sans-serif;
}

/* CONTENT */

.contentor {
	display: grid;
	grid-template-columns: 75% 25%;
  display: flex;
  justify-content: space-between;
}


/* LEFT CONTENT */

.left-content {
	display: grid;
	grid-template-rows: 50% 50%;
	background: #f6f7fb;
	margin: 15px;
	padding: 20px;
	border-radius: 15px;
}

/* ACTIVITIES */

.activities h1 {
	margin: 0 0 20px;
	font-size: 1.4rem;
	font-weight: 700;
}

.activity-container {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 150px);
	column-gap: 10px;
}

.img-one {
	grid-area: 1 / 1 / 2 / 2;
}

.img-two {
	grid-area: 2 / 1 / 3 / 2;
}

.img-three {
	display: block;
	grid-area: 1 / 2 / 3 / 4;
}

.img-four {
	grid-area: 1 / 4 / 2 / 5;
}

.img-five {
	grid-area: 1 / 5 / 2 / 6;
}

.img-six {
	display: block;
	grid-area: 2 / 4 / 3 / 6;
}

.image-container {
	position: relative;
	margin-bottom: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
	border-radius: 10px;
}

.image-container img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	object-fit: cover;
}

.overlay {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		180deg,
		transparent,
		transparent,
		rgba(3, 3, 185, 0.5)
	);
	border-radius: 10px;
	transition: all 0.6s linear;
}

.image-container:hover .overlay {
	opacity: 0;
}

.overlay h3 {
	margin-bottom: 8px;
	margin-right: 10px;
	color: #fff;
}

/* LEFT BOTTOM */

.left-bottom {
	display: grid;
	grid-template-columns: 55% 40%;
	gap: 40px;
}

/* WEEKLY SCHEDULE */

.weekly-schedule {
	display: flex;
	flex-direction: column;
}

.weekly-schedule h1 {
	margin-top: 20px;
	font-size: 1.3rem;
	font-weight: 700;
}

.calendar {
	margin-top: 10px;
}

.day-and-activity {
	display: grid;

	grid-template-columns: 15% 60% 25%;
	align-items: center;
	border-radius: 14px;
	margin-bottom: 5px;
	color: #484d53;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.activity-one {
	background-color: rgb(124, 136, 224, 0.5);
	background-image: linear-gradient(240deg, rgb(124, 136, 224) 0%, #c3f4fc 100%);
}

.activity-two {
	background-color: #aee2a4a1;
	background-image: linear-gradient(240deg, #e5a243ab 0%, #f7f7aa 90%);
}

.activity-three {
	background-color: #ecfcc376;
	background-image: linear-gradient(240deg, #97e7d1 0%, #ecfcc3 100%);
}

.activity-four {
	background-color: #e6a7c3b5;
	background-image: linear-gradient(240deg, #fc8ebe 0%, #fce5c3 100%);
}

.day {
	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translateY(-10px);
}

.day h1 {
	font-size: 1.6rem;
	font-weight: 600;
}

.day p {
	text-transform: uppercase;
	font-size: 0.9rem;
	font-weight: 600;
	transform: translateY(-3px);
}

.activity {
	border-left: 3px solid #484d53;
}

.participants {
	display: flex;
	margin-left: 20px;
}

.participants img {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	z-index: calc(2 * var(--i));
	margin-left: -10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.activity h2 {
	margin-left: 10px;
	font-size: 1.25rem;
	font-weight: 600;
	border-radius: 12px;
}

.btn {
	display: block;
	padding: 8px 24px;
	margin: 10px auto;
	font-size: 1.1rem;
	font-weight: 500;
	outline: none;
	text-decoration: none;
	color: #484b57;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 25px;
	cursor: pointer;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn:visited {
	transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
	animation: gelatine 0.5s 1;
}

@keyframes gelatine {
	0%,
	100% {
		transform: scale(1, 1);
	}
	25% {
		transform: scale(0.9, 1.1);
	}
	50% {
		transform: scale(1.1, 0.9);
	}
	75% {
		transform: scale(0.95, 1.05);
	}
}

/* PERSONAL BESTS */

.personal-bests {
	display: block;
}

.personal-bests h1 {
	margin-top: 20px;
	font-size: 1.3rem;
	font-weight: 700;
}

.personal-bests-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 150px);
	gap: 10px;
	margin-top: 10px;
}

.best-item {
	display: flex;
	gap: 20px;
	width: 100%;
	height: 100%;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.box-one {
	flex-direction: row;
	align-items: center;
	justify-content: center;
	grid-area: 1 / 1 / 2 / 3;
	background-color: rgba(185, 159, 237, 0.6);
	padding: 15px;
	font-size: 1rem;
	font-weight: 700;
}

.box-one img {
	max-width: 100px;
	aspect-ratio: 4/3;
}

.box-two {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.9rem;
	font-weight: 700;
	padding: 10px;
	grid-area: 2 / 1 / 3 / 2;
	background-color: rgba(238, 184, 114, 0.6);
}

.box-two img {
	max-width: 90px;
	aspect-ratio: 3/2;
	align-self: flex-end;
}

.box-three {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.9rem;
	font-weight: 700;
	padding: 10px;
	grid-area: 2 / 2 / 3 / 3;
	background-color: rgba(184, 224, 192, 0.6);
}

.box-three img {
	max-width: 70px;
	aspect-ratio: 1/1;
	align-self: flex-end;
}

/* RIGHT CONTENT */

.right-content {
	display: grid;
	grid-template-rows: 5% 20% 75%;
	background: #f6f7fb;
	margin: 15px 15px 15px 0;
	padding: 10px 0;
	border-radius: 15px;
}

/* USER INFO */

.user-info {
	display: grid;
	grid-template-columns: 30% 55% 15%;
	align-items: center;
	padding: 0 10px;
}

.icon-container {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  border-radius: 50%; /* Makes the background round */
  background-color: #ccc; /* Sets the initial background color to grey */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.icon-container:hover {
  background-color: #999; /* Changes the background color on hover */
}
.icon-container {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  min-width: 220px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.icon-container:hover .dropdown-content {
  display: block;
  
}

.dropdown-content a {
  display: block;
  padding-left: 8px;
 padding-top: 5px;
  text-decoration: none;
  color: #333;
  
}

.dropdown-content a:hover {
  background-color: #f0f0f0;
  
}
.user-info h4 {
	margin-left: 40px;
}

.user-info img {
	width: 60px;
}

/* ACTIVE CALORIES  */

.active-calories {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(214, 227, 248);
	padding: 0 10px;
	margin: 15px 10px 0;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.active-calories h1 {
	margin-top: 10px;
	font-size: 1.2rem;
}

.active-calories-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.calories-content p {
	font-size: 1rem;
}

.calories-content p span {
	font-weight: 700;
}

.box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	padding: 10px 0;
	/* width: 200px; */
}

.box h2 {
	position: relative;
	text-align: center;
	font-size: 1.25rem;
	color: rgb(91, 95, 111);
	font-weight: 600;
}

.box h2 small {
	font-size: 0.8rem;
	font-weight: 600;
}

.circle {
	position: relative;
	width: 80px;
	aspect-ratio: 1/1;
	background: conic-gradient(
		from 0deg,
		#590b94 0%,
		#590b94 0% var(--i),
		#b3b2b2 var(--i),
		#b3b2b2 100%
	);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.circle::before {
	content: "";
	position: absolute;
	inset: 10px;
	background-color: rgb(214, 227, 248);
	border-radius: 50%;
}

/* MOBILE PERSONAL BESTS  */

.mobile-personal-bests {
	display: none;
}

/* FRIEND ACTIVITIES  */

.friends-activity {
	display: flex;
	flex-direction: column;
}

.friends-activity h1 {
	font-size: 1.2rem;
	font-weight: 700;
	margin: 15px 0 10px 15px;
}

.card-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.card {
	background-color: #fff;
	margin: 0 10px;
	padding: 5px 7px;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.card-two {
	display: block;
}

.card-user-info {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 5px;
}

.card-user-info img {
	width: 25px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	object-fit: cover;
}

.card-user-info h2 {
	font-size: 1rem;
	font-weight: 700;
	margin-left: 5px;
}

.card-img {
	display: block;
	width: 100%;
	aspect-ratio: 7/4;
	margin-bottom: 10px;
	object-fit: cover;
	border-radius: 10px;
	object-position: 50% 30%;
}

.card p {
	font-size: 0.9rem;
	padding: 0 5px 5px;
}

@media (max-width: 1500px) {
	main {
		grid-template-columns: 6% 94%;
	}

	.main-menu h1 {
		display: none;
	}

	.logo {
		display: block;
		width: 30px;
		margin: 20px auto;
	}

	.nav-text {
		display: none;
	}

	.contentor {
		grid-template-columns: 70% 30%;
	}
}

@media (max-width: 1310px) {
	main {
		grid-template-columns: 8% 92%;
		margin: 30px;
	}

	.contentor {
		grid-template-columns: 65% 35%;
	}

	.day-and-activity {
		margin-bottom: 10px;
	}

	.btn {
		padding: 8px 16px;
		margin: 10px 0;
		margin-right: 10px;
		font-size: 1rem;
	}

	.personal-bests-container {
		grid-template-rows: repeat(3, 98px);
		gap: 15px;
	}

	.box-one {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 1 / 1 / 2 / 3;
		padding: 10px;
		font-size: 0.9rem;
	}

	.box-one img {
		max-width: 80px;
	}

	.box-two {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 2 / 1 / 3 / 3;
	}

	.box-two img {
		max-width: 70px;
		align-self: center;
	}

	.box-three {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 3 / 1 / 4 / 3;
	}

	.box-three img {
		max-width: 60px;
		align-self: center;
	}

	.right-content {
		grid-template-rows: 4% 20% 76%;
		margin: 15px 15px 15px 0;
	}
}

@media (max-width: 1150px) {
	.contentor {
		grid-template-columns: 60% 40%;
	}

	.left-content {
		grid-template-rows: 50% 50%;
		margin: 15px;
		padding: 20px;
	}

	.btn {
		padding: 8px 8px;
		font-size: 0.9rem;
	}

	.personal-bests-container {
		grid-template-rows: repeat(3, 70px);
		gap: 10px;
	}

	.activity-container {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 150px);
	}

	.img-one {
		grid-area: 1 / 1 / 2 / 2;
	}

	.img-two {
		grid-area: 2 / 1 / 3 / 2;
	}

	.img-three {
		display: none;
	}

	.img-four {
		grid-area: 1 / 2 / 2 / 3;
	}

	.img-five {
		grid-area: 1 / 3 / 2 / 4;
	}

	.img-six {
		grid-area: 2 / 2 / 3 / 4;
	}

	.left-bottom {
		grid-template-columns: 100%;
		gap: 0;
	}

	.right-content {
		grid-template-rows: 4% 19% 36% 41%;
	}

	.personal-bests {
		display: none;
	}

	.mobile-personal-bests {
		display: block;
		margin: 0 10px;
	}

	.mobile-personal-bests h1 {
		margin-top: 20px;
		font-size: 1.2rem;
	}

	.card-two {
		display: none;
	}

	.card-img {
		aspect-ratio: 16/9;
	}
}

@media (max-width: 1050px) {
	.right-content {
		grid-template-rows: 5% 19% 36% 40%;
	}
}

@media (max-width: 910px) {
	main {
		grid-template-columns: 10% 90%;
		margin: 20px;
	}

	.contentor {
		grid-template-columns: 55% 45%;
	}

	.left-content {
		grid-template-rows: 50% 50%;
		padding: 30px 20px 20px;
	}

	.activity-container {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 150px);
	}

	.img-one {
		grid-area: 1 / 1 / 2 / 2;
	}

	.img-two {
		grid-area: 2 / 1 / 3 / 2;
	}

	.img-three {
		display: none;
	}

	.img-four {
		grid-area: 1 / 2 / 2 / 3;
	}

	.img-five {
		grid-area: 2 / 2 / 3 / 3;
	}

	.img-six {
		display: none;
	}
}

@media (max-width: 800px) {
	.content {
		grid-template-columns: 52% 48%;
	}
}

@media (max-width: 700px) {
	main {
		grid-template-columns: 15% 85%;
	}

	.contentor {
		grid-template-columns: 100%;
		grid-template-rows: 45% 55%;
		grid-template-areas:
			"rightContent"
			"leftContent";
	}

	.left-content {
		grid-area: leftContent;
		grid-template-rows: 45% 55%;
		padding: 10px 20px 10px;
	}

	.right-content {
		grid-area: rightContent;
		grid-template-rows: 5% 40% 50%;
		margin: 15px 15px 0 15px;
		padding: 10px 0 0;
		gap: 15px;
	}

	.activities,
	.weekly-schedule {
		margin-top: 10px;
	}

	.active-calories-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	.friends-activity {
		display: none;
	}
}
/* Add your custom CSS here */
    body {
      margin: 0;
      padding: 0;
    }
    main {
      display: flex;
      flex-direction: column;
    }
    .main-menu {
      order: 2; /* Change the order to 2 for small devices */
    }

.contentor {
  display: flex;
  flex-direction: column;
  
}

footer {
  margin-top: auto; /* Push footer to the bottom */
}
    @media screen and (min-width: 768px) {
      .contentor {
        flex-direction: row;
      }
      .left-content {
        order: 1; /* Change the order to 2 for small devices */
      }
      .right-content {
        order: 2; /* Change the order to 1 for small devices */
      }
    }
    
    
    /*****Details css***/

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

/* Shadows */
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Borders */
.rounded {
  border-radius: 0.25rem;
}

.rounded-full {
  border-radius: 9999px;
}

/* Buttons */
.inline-flex {
  display: inline-flex;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  user-select: none;
}

.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-x-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.border {
  border-width: 1px;
}

.bg-white {
  background-color: #ffffff;
}

.bg-yellow-100 {
  background-color: #fefcbf;
}

.bg-green-100 {
  background-color: #c6f6d5;
}

.bg-blue-100 {
  background-color: #c7ebf2;
}

.bg-indigo-100 {
  background-color: #d4d7fb;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.bg-blue-700 {
  background-color:#022D36;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, #10b981, #3b82f6);
}

/* Text */
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-slate-900 {
  color: #111827;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-gray-600 {
  color: #718096;
}

.text-yellow-800 {
  color: #7f9c2d;
}

.text-green-800 {
  color: #047857;
}

.text-blue-800 {
  color: #185adb;
}

.text-indigo-800 {
  color: #4338ca;
}

.text-gray-800 {
  color: #4b5563;
}

.text-stone-500 {
  color: #718096;
}

.text-slate-700 {
  color: #374151;
}

.text-slate-500 {
  color: #6b7280;
}

.text-slate-600 {
  color: #4b5563;
}

.text-white {
  color: #ffffff;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

/* Images */
.object-center {
  object-position: center;
}

.object-cover {
  object-fit: cover;
}

/* Flex */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Padding and Margins */
.p-5 {
  padding: 1.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.ml-auto {
  margin-left: auto;
}

/* Width */
.w-full {
  width: 100%;
}

.md:w-1/2 {
  width: 50%;
}

.w-[90px] {
  width: 90px;
}

/* Height */
.h-[140px] {
  height: 140px;
}

.h-[90px] {
  height: 90px;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

/* Border */
.border-4 {
  border-width: 4px;
}

/* Miscellaneous */
.rounded-sm {
  border-radius: 0.375rem;
}

.self-start {
  align-self: flex-start;
}

.leading-3 {
  line-height: 0.75rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-2 {
  gap: 0.5rem;
}

/* Transitions */
.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Hover Effects */
.hover\:border-gray-300:hover {
  border-color: #d1d5db;
}

.hover\:bg-gray-100:hover {
  background-color: #f3f4f6;
}

.hover\:bg-blue-600:hover {
  background-color: #2563eb;
}

.hover\:text-white:hover {
  color: #ffffff;
}

/* Focus Effects */
.focus\:border-gray-300:focus {
  border-color: #d1d5db;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus {
  box-shadow: 0 0 0 2px rgba(163, 169, 174, 0.5);
}

.focus\:ring-blue-300:focus {
  box-shadow: 0 0 0 2px rgba(164, 202, 254, 0.5);
}

.focus\:ring-gray-300:focus {
  box-shadow: 0 0 0 2px rgba(209, 213, 219, 0.5);
}

/* Active Effects */
.active\:bg-white:active {
  background-color: #ffffff;
}

.active\:bg-blue-700:active {
  background-color: #2c5282;
}

.active\:text-white:active {
  color: #ffffff;
}

.detailer {
  justify-content: center;
  width: 800px;
  margin: 50px auto 0 auto; /* Adjust the top margin as needed */
  padding: 10px;
}
  @media only screen and (max-width: 859px) {
.detailer{

  width: 400px;
  
}
    
    
    
  }
