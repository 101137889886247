@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Roboto:wght@300;400;500;700&display=swap);

@tailwind base;
@tailwind components;
@tailwind utilities;
 html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none!important;
    color: #022D36;
  }
  a:hover {
    color: #D0312D;
  }

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Change the color to match your background */
}
  body {
    background: #fff;
    color: #0B093B;
    font-family: 'Khula', sans-serif;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
  }
  .container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 30px;
  }
  .padding{
   padding: 20px; 

    
  }
  @media only screen and (max-width: 859px) {
    .container {
      padding: 0 15px;
    }
  }
  .regular {
    font-weight: 400!important;
  }
  .semiBold {
    font-weight: 600!important;
  }
  .extraBold {
    font-weight: 700!important;
  }


.overlayz{
 
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9) !important; /* Overlay background color with transparency */
  color: white;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
}
  /* HELLPERS */
  .textCenter {
    text-align: center
  }
  .animate {
    transition: 0.3s ease;
  }
  .pointer {
    cursor: pointer;
  }
  .relative {
    position: relative;
  }
  .textCenter {
    text-align: center;
  }
  .textLeft {
    text-align: left;
  }
  .textRight {
    text-align: right;
  }
  .x100 {
    width: 100%;
  }
  .active {
    border-bottom: 2px solid #D0312D;
    color: #D0312D;
  }
  .tag {
    padding: 5px 10px;
    margin: 10px 0;
  }
  .shadow {
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
  }
  /* FLEXBOX */
  .flex {
    display: flex;
  }
  .flexSpaceCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexNullCenter {
    display: flex;
    align-items: center;
    
  }
  .flexColumn {
    flex-direction: column;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexSpaceNull {
    display: flex;
    justify-content: space-between;
  }
.leftor{
  
  align-items: left;
  text-align: left;
  list-style-type: disc;
  
}
.leftor li{
  
  padding: 5px;
}
  /* FONT SIZES */
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.75rem;
  }
  .font13 {
    font-size: 0.813rem;
  }
  .font14 {
    font-size: 0.875rem;
  }
  .font15 {
    font-size: 0.938rem;
  }
  .font18 {
    font-size: 1.125rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
  .font25 {
    font-size: 1.563rem;
  }
  .font30 {
    font-size: 1.875rem;
  }
  .font40 {
    font-size: 2.5rem;
  }
  .font60 {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
  @media only screen and (max-width: 859px) {
    .font11 {
      font-size: 0.688rem;
    }
    .font12 {
      font-size: 0.688rem;
    }
    .font13 {
      font-size: 0.75rem;
    }
    .font14 {
      font-size: 0.813rem;
    }
    .font15 {
      font-size: 0.813rem;
    }
    .font18 {
      font-size: 0.938rem;
    }
    .font20 {
      font-size: 1.125rem;
    }
    .font25 {
      font-size: 1.25rem;
    }
    .font30 {
      font-size: 1.5rem;
    }
    .font40 {
      font-size: 1.875rem;
    }
    .font60 {
      font-size: 2.8rem;
    }
    
  }
  
  .margin {
  display: none; /* Hide by default */
}
.Button1{
  display:none;
 
}
@media only screen and (max-width: 768px) {
  .Button1 {
    display: block; /* or any other style you want */
    margin-left: 40px;
    float: left;
  }
}
/* Media query for small devices (up to 768px width) */
@media only screen and (max-width: 768px) {
  .margin {
    display: flex; /* Show on small devices */
    margin-top: 10px;
    height: 45%;
    position: absolute;
  }
}
  /* BORDER RADIUS */

  .radius8 {
    border-radius: 1rem;
  }
  .radius6 {
    border-radius: 0.6rem;
  }
  /* COLORS */
  .darkColor {
    color: #022D36;
  }
  .cc{
        color: #D0312D;
  }
  .purpleColor {
    color: #D0312D;
  }
  .orangeColor {
    color: #F2B300;
  }
  .lightColor {
    color: #F5F5F5;
  }
  .coralColor {
    color: #C4FFFF;
  }
  .greenColor {
    color: #49CB86;
  }
  .greyColor {
    color: #707070;
  }
  .whiteColor {
    color: #fff;
  }
  /* BACKGROUNDS */
  .darkBg {
    background-color: #022D36;
  }
  
  .liBg{
    
    background-color: #ececec;
  }
  .purpleBg {
    background-color: #D0312D;
  }
  .orangeBg {
    background-color: #F2B300;
  }
  .lightBg {
    background-color: #F5F5F5;
  }
  .hardBg{
    
    background-color: #022D36;
 
  }
  .coralBg {
    background-color: #C4FFFF;
  }
  .greenBg {
    background-color: #49CB86;
  }
  .greyBg {
    background-color: #707070;
  }
  .whiteBg {
    background-color: #fff;
  }



/******* Search Bar css****/

/* SearchBar.css */
.search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 150px;
  box-sizing: border-box;
}


.search-container {
  position: relative; /* Make the container a positioning context */
}

.search-suggestions {
  position: absolute;
  top: 100%; /* Position the suggestions below the search input */
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2px); /* Adjust width to match the container's width */
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}
/* SearchBar.css */
.search-container {
  display: flex;
  align-items: center; /* Align items vertically */
}

.search-input {
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px; /* Adjust border-radius */
  padding: 8px;

  box-sizing: border-box;
}

.search-button {
  background-color: #D0312D;
  color: #fff;
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 8px 16px;
  cursor: pointer;
  
}
/* Community.css */

.first-text {
  font-weight: light;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}


.text-steps-container {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

.second-text {
  font-size: 20px;
  font-weight: medium;
  color: rgba(0, 0, 0, 0.8);
  display: inline-block;
}

.steps-list {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.step {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.step-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
}

.step-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bo{
  border: 1px solid #D0312D;
  background-color: #D0312D;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  border-radius: 20px;
}
  .red{
    
    color: red;
  }
  .green{
    
    color: green ;
    
  }
  
  
.card {
    width: 288px;
    height: auto;
    background-color: white;
    border-radius: 1rem;
    
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 10px; /* Adjust as needed */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}

figure {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    position: relative;
}

figure img {
    width: 100%;
    height: 80%;
    object-fit: cover;
}

figure img:hover {
    transition: all 0.6s ease-in-out;
    transform: scale(1.1);
}

.icons svg {
    fill: white;
    
}


.btnAddNone{
    display: none;
}

.btnAdd span {
    color: white;
}

.btnAdd:hover {
    background-color: gray;
    
}



.descriptionCard {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
  
}

.informationCard1 {
    align-items: center;
  
    border-bottom: 1px solid #D3D3D3;
}


.shortInformation {
    padding-right: 30px;
}

.shortInformation h3 {
    font-size: 16px;
    font-weight: 700;

}

.shortInformation p {
    font-size: 12px;
    color: #A9A9A9;
}
.state{
   color:green;
  padding: 10px;
  
}
.btnComprar {
    height: 32px;
    font-size: 10px;
    background-color: #022D36;
    border-radius: 100px;
    border: none;
}

.btnComprar:hover {
    cursor: pointer;
    background-color: #022D36;
}

.btnComprar span {
    color: white;
    margin-left: 26px;
    margin-right: 26px;
    font-weight: 500;
}

.priceDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
}
.marketcount{
    position: relative;
    padding-right: 5px;
}


.priceDescription p {
    font-size: 16px;
    font-weight: 600;
    color: #001547;
}
.svg {

  height: 30px;
  width: 30px;
}
.location-status-container {
  display: flex;
  align-items: center;
}

.location-container{
  display: flex;
  
 
}

.status-container img {
  
  width: 20px;
  height: 20px;
}


.showcase {
 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.travel-card {
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;

  background-color: white;
  box-shadow: 0px 0px 100px -10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 300px;
}
.travel-card:hover > .button-favorite {
  display: inline-flex;
}
.travel-card{
  
}
.travel-card .image {
  width: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
  height: 157px;
}

 .image  img {
  width: 100%;

}
.travel-card > .content {
  color: #545454;
  padding: 1em 1.5em;
}
.travel-card > .content > :nth-child(1n+2) {
  margin-top: 1em;
}
.category {
  font-size: 1em;
  color: #bababa;
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
}


.travel-card > .content > .recommendation {
  display: flex;
  align-items: center;
}
.travel-card > .content > .recommendation > .score {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #D0312D;
font-size:10px;
  color: white;
  padding: 5px 5px;

}
.score {
  padding-top: 1px;
  padding-left: 4px;
}

.travel-card > .content > .recommendation > .comment {
  color: #bababa;
  margin-left: 1.5em;
}
.travel-card > .content > .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discount-info {
  color: #4fc3f7;
}
.original-price {
  font-size: 1em;
  
}
.location-image {
  vertical-align: middle;
  margin-right: 5px;
}

.travel-card > .button-favorite {
  
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
}




:root {
  --v-50: #d8e6ea;
  --v-100: #9cbecb;
  --v-200: #6f9dac;
  --v-300: #42748e;
  --v-400: #1a4c6f;
  --v-700: #022D36; 
}

.breadcrumbs {
  width: 100%;
  list-style: none;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  
  padding: 0.2rem;
  border-radius: 0.5rem;

  font-size: 10px;
}

.breadcrumbs-item {
  padding: 0.2rem;
  text-align: center;
  white-space: nowrap;
  background: var(--v-100);
  color: var(--v-700);
  text-decoration: none;
  text-transform: uppercase;
 width:100%;
  position: relative;
}

.breadcrumbs-item::before,
.breadcrumbs-item::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0.5rem;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
          clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.breadcrumbs-item::before {
  background: var(--v-50)
}

.breadcrumbs-item::after {
  left: 100%;
  background: var(--v-100);
}

.breadcrumbs-item:first-child {
  padding: 0.2rem;
  border-radius: 0.25rem 0 0 0.25rem;
}

.breadcrumbs-item:first-child::before {
  display: none;
}

.breadcrumbs-item:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

.breadcrumbs-item:last-child::after {
  display: none;
}

.breadcrumbs-item:hover,
.breadcrumbs-item:hover::after {
  
  color: var(--v-100);
}

@media only screen and (max-width: 768px) {
.question {
  margin-top:-100px;
}
.about {
  
  margin-top: -180px;
}
}

/********* Login / signup ***********/


.auth {
  width: 70%;
  background-color: #fcf2f2;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button-container button {
    padding: 10px 20px;
    background-color: #022D36;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-container button:disabled {
    background-color: #A0A0A0;
    color: #FFF;
    cursor: not-allowed;
}
@media only screen and (max-width: 768px) {
  
  .auth{
  width: 90%;
  }
}
.video-container {
  position: relative;
  width: 100%; 
  margin-top: 12rem;
}

.thumbnail {
  width: 100%;
  height: auto;
  cursor: pointer;
}



.overlay-content {
  text-align: center;
  color: #fff;
}

.video-container:hover .overlay {
  display: flex;
}
.play-container {
  position: relative;
  display: inline-block;
 
  /* Ensure inline-block to contain the absolute positioning */
}
.play-icon {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  position: absolute;
  margin-left:-25px;
  margin-top:30px;
}

.play-animation {
  position: relative;
  transform: translate(-50%, -50%);
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3); /* Adjust color and opacity */
  animation: pulse 2s infinite ease-in-out; /* Adjust animation duration and timing */
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
 .videoz{
   width:470px;
   margin: 0 auto;
 }
 
  
@media only screen and (max-width: 768px) {
  .video {
    display: flex; /* Show on small devices */
    margin-top: 10px;
    height: 45%;
    position: absolute;
  }
 .videoz {
width: 380px;
margin: 0 auto;
}
}


.section {
 
  width: 100%;
  display: grid;
  place-items: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.column {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}
.cardz {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #022D36 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}
.section h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
}
.section p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}
.icon-wrapper {
  background-color: #022D36;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.cardz:hover {
  background-position: 0 100%;
}
.cardz:hover .icon-wrapper {
  background-color: #ffffff;
  color: #022D36;
}
.cardz:hover h3 {
  color: #ffffff;
}
.cardz:hover p {
  color: #f0f0f0;
}
@media screen and (min-width: 768px) {
  .section {
    padding: 0 2em;
  }
  .column {
    flex: 0 1 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .section {
    padding: 1em 3em;
  }
  .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
/* Question.css */
.accordion-item {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 1rem;
  text-align: left;
}

.accordion-header {
  
  justify-content: space-between;
}

.accordion-button {
 
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.accordion-title {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: left;
}

.accordion-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.plus-icon {
  color: #718096; /* gray */
}

.minus-icon {
  color: #4299e1; /* blue */
}

.accordion-content {
  margin-top: 0.5rem;
}

.answer-text {
  color: #4a5568; /* gray */
}
*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}



.main {
	display: grid;
	grid-template-columns: 13% 87%;
	width: 100%;
	margin: 10px;
	background: rgb(254, 254, 254);

	z-index: 10;
}

.main-menu {
	overflow: hidden;
	background: rgb(73, 57, 113);
	padding-top: 10px;
	border-radius: 15px 0 0 15px;
	font-family: "Roboto", sans-serif;
}

.main-menu h1 {
	display: block;
	font-size: 1.5rem;
	font-weight: 500;
	text-align: center;
	margin: 20px 0 30px;
	color: #fff;
	font-family: "Nunito", sans-serif;
}

.logo {
	display: none;
}

.nav-item {
	position: relative;
	display: block;
}

.nav-item a {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 1rem;
	padding: 15px 0;
	margin-left: 10px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.nav-item b:nth-child(1) {
	position: absolute;
	top: -15px;
	height: 15px;
	width: 100%;
	background: #fff;
	display: none;
}

.nav-item b:nth-child(1)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	border-bottom-right-radius: 20px;
	background: rgb(73, 57, 113);
}

.nav-item b:nth-child(2) {
	position: absolute;
	bottom: -15px;
	height: 15px;
	width: 100%;
	background: #fff;
	display: none;
}

.nav-item b:nth-child(2)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-top-right-radius: 20px;
	background: rgb(73, 57, 113);
}

.nav-item.active b:nth-child(1),
.nav-item.active b:nth-child(2) {
	display: block;
}

.nav-item.active a {
	text-decoration: none;
	color: #000;
	background: rgb(254, 254, 254);
}

.nav-icon {
	width: 60px;
	height: 20px;
	font-size: 20px;
	text-align: center;
}

.nav-text {
	display: block;
	width: 120px;
	height: 20px;
	font-family: "Mulish", sans-serif;
}

/* CONTENT */

.contentor {
	display: grid;
	grid-template-columns: 75% 25%;
  display: flex;
  justify-content: space-between;
}


/* LEFT CONTENT */

.left-content {
	display: grid;
	grid-template-rows: 50% 50%;
	background: #f6f7fb;
	margin: 15px;
	padding: 20px;
	border-radius: 15px;
}

/* ACTIVITIES */

.activities h1 {
	margin: 0 0 20px;
	font-size: 1.4rem;
	font-weight: 700;
}

.activity-container {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 150px);
	grid-column-gap: 10px;
	column-gap: 10px;
}

.img-one {
	grid-area: 1 / 1 / 2 / 2;
}

.img-two {
	grid-area: 2 / 1 / 3 / 2;
}

.img-three {
	display: block;
	grid-area: 1 / 2 / 3 / 4;
}

.img-four {
	grid-area: 1 / 4 / 2 / 5;
}

.img-five {
	grid-area: 1 / 5 / 2 / 6;
}

.img-six {
	display: block;
	grid-area: 2 / 4 / 3 / 6;
}

.image-container {
	position: relative;
	margin-bottom: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
	border-radius: 10px;
}

.image-container img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	object-fit: cover;
}

.overlay {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		180deg,
		transparent,
		transparent,
		rgba(3, 3, 185, 0.5)
	);
	border-radius: 10px;
	transition: all 0.6s linear;
}

.image-container:hover .overlay {
	opacity: 0;
}

.overlay h3 {
	margin-bottom: 8px;
	margin-right: 10px;
	color: #fff;
}

/* LEFT BOTTOM */

.left-bottom {
	display: grid;
	grid-template-columns: 55% 40%;
	grid-gap: 40px;
	gap: 40px;
}

/* WEEKLY SCHEDULE */

.weekly-schedule {
	display: flex;
	flex-direction: column;
}

.weekly-schedule h1 {
	margin-top: 20px;
	font-size: 1.3rem;
	font-weight: 700;
}

.calendar {
	margin-top: 10px;
}

.day-and-activity {
	display: grid;

	grid-template-columns: 15% 60% 25%;
	align-items: center;
	border-radius: 14px;
	margin-bottom: 5px;
	color: #484d53;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.activity-one {
	background-color: rgb(124, 136, 224, 0.5);
	background-image: linear-gradient(240deg, rgb(124, 136, 224) 0%, #c3f4fc 100%);
}

.activity-two {
	background-color: #aee2a4a1;
	background-image: linear-gradient(240deg, #e5a243ab 0%, #f7f7aa 90%);
}

.activity-three {
	background-color: #ecfcc376;
	background-image: linear-gradient(240deg, #97e7d1 0%, #ecfcc3 100%);
}

.activity-four {
	background-color: #e6a7c3b5;
	background-image: linear-gradient(240deg, #fc8ebe 0%, #fce5c3 100%);
}

.day {
	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translateY(-10px);
}

.day h1 {
	font-size: 1.6rem;
	font-weight: 600;
}

.day p {
	text-transform: uppercase;
	font-size: 0.9rem;
	font-weight: 600;
	transform: translateY(-3px);
}

.activity {
	border-left: 3px solid #484d53;
}

.participants {
	display: flex;
	margin-left: 20px;
}

.participants img {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	z-index: calc(2 * var(--i));
	margin-left: -10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.activity h2 {
	margin-left: 10px;
	font-size: 1.25rem;
	font-weight: 600;
	border-radius: 12px;
}

.btn {
	display: block;
	padding: 8px 24px;
	margin: 10px auto;
	font-size: 1.1rem;
	font-weight: 500;
	outline: none;
	text-decoration: none;
	color: #484b57;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 25px;
	cursor: pointer;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn:visited {
	transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
	animation: gelatine 0.5s 1;
}

@keyframes gelatine {
	0%,
	100% {
		transform: scale(1, 1);
	}
	25% {
		transform: scale(0.9, 1.1);
	}
	50% {
		transform: scale(1.1, 0.9);
	}
	75% {
		transform: scale(0.95, 1.05);
	}
}

/* PERSONAL BESTS */

.personal-bests {
	display: block;
}

.personal-bests h1 {
	margin-top: 20px;
	font-size: 1.3rem;
	font-weight: 700;
}

.personal-bests-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 150px);
	grid-gap: 10px;
	gap: 10px;
	margin-top: 10px;
}

.best-item {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	width: 100%;
	height: 100%;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.box-one {
	flex-direction: row;
	align-items: center;
	justify-content: center;
	grid-area: 1 / 1 / 2 / 3;
	background-color: rgba(185, 159, 237, 0.6);
	padding: 15px;
	font-size: 1rem;
	font-weight: 700;
}

.box-one img {
	max-width: 100px;
	aspect-ratio: 4/3;
}

.box-two {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.9rem;
	font-weight: 700;
	padding: 10px;
	grid-area: 2 / 1 / 3 / 2;
	background-color: rgba(238, 184, 114, 0.6);
}

.box-two img {
	max-width: 90px;
	aspect-ratio: 3/2;
	align-self: flex-end;
}

.box-three {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.9rem;
	font-weight: 700;
	padding: 10px;
	grid-area: 2 / 2 / 3 / 3;
	background-color: rgba(184, 224, 192, 0.6);
}

.box-three img {
	max-width: 70px;
	aspect-ratio: 1/1;
	align-self: flex-end;
}

/* RIGHT CONTENT */

.right-content {
	display: grid;
	grid-template-rows: 5% 20% 75%;
	background: #f6f7fb;
	margin: 15px 15px 15px 0;
	padding: 10px 0;
	border-radius: 15px;
}

/* USER INFO */

.user-info {
	display: grid;
	grid-template-columns: 30% 55% 15%;
	align-items: center;
	padding: 0 10px;
}

.icon-container {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  border-radius: 50%; /* Makes the background round */
  background-color: #ccc; /* Sets the initial background color to grey */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.icon-container:hover {
  background-color: #999; /* Changes the background color on hover */
}
.icon-container {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  min-width: 220px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.icon-container:hover .dropdown-content {
  display: block;
  
}

.dropdown-content a {
  display: block;
  padding-left: 8px;
 padding-top: 5px;
  text-decoration: none;
  color: #333;
  
}

.dropdown-content a:hover {
  background-color: #f0f0f0;
  
}
.user-info h4 {
	margin-left: 40px;
}

.user-info img {
	width: 60px;
}

/* ACTIVE CALORIES  */

.active-calories {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(214, 227, 248);
	padding: 0 10px;
	margin: 15px 10px 0;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.active-calories h1 {
	margin-top: 10px;
	font-size: 1.2rem;
}

.active-calories-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
}

.calories-content p {
	font-size: 1rem;
}

.calories-content p span {
	font-weight: 700;
}

.box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	padding: 10px 0;
	/* width: 200px; */
}

.box h2 {
	position: relative;
	text-align: center;
	font-size: 1.25rem;
	color: rgb(91, 95, 111);
	font-weight: 600;
}

.box h2 small {
	font-size: 0.8rem;
	font-weight: 600;
}

.circle {
	position: relative;
	width: 80px;
	aspect-ratio: 1/1;
	background: conic-gradient(
		from 0deg,
		#590b94 0%,
		#590b94 0% var(--i),
		#b3b2b2 var(--i),
		#b3b2b2 100%
	);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.circle::before {
	content: "";
	position: absolute;
	inset: 10px;
	background-color: rgb(214, 227, 248);
	border-radius: 50%;
}

/* MOBILE PERSONAL BESTS  */

.mobile-personal-bests {
	display: none;
}

/* FRIEND ACTIVITIES  */

.friends-activity {
	display: flex;
	flex-direction: column;
}

.friends-activity h1 {
	font-size: 1.2rem;
	font-weight: 700;
	margin: 15px 0 10px 15px;
}

.card-container {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
}

.card {
	background-color: #fff;
	margin: 0 10px;
	padding: 5px 7px;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
}

.card-two {
	display: block;
}

.card-user-info {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 5px;
}

.card-user-info img {
	width: 25px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	object-fit: cover;
}

.card-user-info h2 {
	font-size: 1rem;
	font-weight: 700;
	margin-left: 5px;
}

.card-img {
	display: block;
	width: 100%;
	aspect-ratio: 7/4;
	margin-bottom: 10px;
	object-fit: cover;
	border-radius: 10px;
	object-position: 50% 30%;
}

.card p {
	font-size: 0.9rem;
	padding: 0 5px 5px;
}

@media (max-width: 1500px) {
	main {
		grid-template-columns: 6% 94%;
	}

	.main-menu h1 {
		display: none;
	}

	.logo {
		display: block;
		width: 30px;
		margin: 20px auto;
	}

	.nav-text {
		display: none;
	}

	.contentor {
		grid-template-columns: 70% 30%;
	}
}

@media (max-width: 1310px) {
	main {
		grid-template-columns: 8% 92%;
		margin: 30px;
	}

	.contentor {
		grid-template-columns: 65% 35%;
	}

	.day-and-activity {
		margin-bottom: 10px;
	}

	.btn {
		padding: 8px 16px;
		margin: 10px 0;
		margin-right: 10px;
		font-size: 1rem;
	}

	.personal-bests-container {
		grid-template-rows: repeat(3, 98px);
		grid-gap: 15px;
		gap: 15px;
	}

	.box-one {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 1 / 1 / 2 / 3;
		padding: 10px;
		font-size: 0.9rem;
	}

	.box-one img {
		max-width: 80px;
	}

	.box-two {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 2 / 1 / 3 / 3;
	}

	.box-two img {
		max-width: 70px;
		align-self: center;
	}

	.box-three {
		flex-direction: row;
		justify-content: space-between;
		grid-area: 3 / 1 / 4 / 3;
	}

	.box-three img {
		max-width: 60px;
		align-self: center;
	}

	.right-content {
		grid-template-rows: 4% 20% 76%;
		margin: 15px 15px 15px 0;
	}
}

@media (max-width: 1150px) {
	.contentor {
		grid-template-columns: 60% 40%;
	}

	.left-content {
		grid-template-rows: 50% 50%;
		margin: 15px;
		padding: 20px;
	}

	.btn {
		padding: 8px 8px;
		font-size: 0.9rem;
	}

	.personal-bests-container {
		grid-template-rows: repeat(3, 70px);
		grid-gap: 10px;
		gap: 10px;
	}

	.activity-container {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 150px);
	}

	.img-one {
		grid-area: 1 / 1 / 2 / 2;
	}

	.img-two {
		grid-area: 2 / 1 / 3 / 2;
	}

	.img-three {
		display: none;
	}

	.img-four {
		grid-area: 1 / 2 / 2 / 3;
	}

	.img-five {
		grid-area: 1 / 3 / 2 / 4;
	}

	.img-six {
		grid-area: 2 / 2 / 3 / 4;
	}

	.left-bottom {
		grid-template-columns: 100%;
		grid-gap: 0;
		gap: 0;
	}

	.right-content {
		grid-template-rows: 4% 19% 36% 41%;
	}

	.personal-bests {
		display: none;
	}

	.mobile-personal-bests {
		display: block;
		margin: 0 10px;
	}

	.mobile-personal-bests h1 {
		margin-top: 20px;
		font-size: 1.2rem;
	}

	.card-two {
		display: none;
	}

	.card-img {
		aspect-ratio: 16/9;
	}
}

@media (max-width: 1050px) {
	.right-content {
		grid-template-rows: 5% 19% 36% 40%;
	}
}

@media (max-width: 910px) {
	main {
		grid-template-columns: 10% 90%;
		margin: 20px;
	}

	.contentor {
		grid-template-columns: 55% 45%;
	}

	.left-content {
		grid-template-rows: 50% 50%;
		padding: 30px 20px 20px;
	}

	.activity-container {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 150px);
	}

	.img-one {
		grid-area: 1 / 1 / 2 / 2;
	}

	.img-two {
		grid-area: 2 / 1 / 3 / 2;
	}

	.img-three {
		display: none;
	}

	.img-four {
		grid-area: 1 / 2 / 2 / 3;
	}

	.img-five {
		grid-area: 2 / 2 / 3 / 3;
	}

	.img-six {
		display: none;
	}
}

@media (max-width: 800px) {
	.content {
		grid-template-columns: 52% 48%;
	}
}

@media (max-width: 700px) {
	main {
		grid-template-columns: 15% 85%;
	}

	.contentor {
		grid-template-columns: 100%;
		grid-template-rows: 45% 55%;
		grid-template-areas:
			"rightContent"
			"leftContent";
	}

	.left-content {
		grid-area: leftContent;
		grid-template-rows: 45% 55%;
		padding: 10px 20px 10px;
	}

	.right-content {
		grid-area: rightContent;
		grid-template-rows: 5% 40% 50%;
		margin: 15px 15px 0 15px;
		padding: 10px 0 0;
		grid-gap: 15px;
		gap: 15px;
	}

	.activities,
	.weekly-schedule {
		margin-top: 10px;
	}

	.active-calories-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 10px;
		gap: 10px;
	}

	.friends-activity {
		display: none;
	}
}
/* Add your custom CSS here */
    body {
      margin: 0;
      padding: 0;
    }
    main {
      display: flex;
      flex-direction: column;
    }
    .main-menu {
      order: 2; /* Change the order to 2 for small devices */
    }

.contentor {
  display: flex;
  flex-direction: column;
  
}

footer {
  margin-top: auto; /* Push footer to the bottom */
}
    @media screen and (min-width: 768px) {
      .contentor {
        flex-direction: row;
      }
      .left-content {
        order: 1; /* Change the order to 2 for small devices */
      }
      .right-content {
        order: 2; /* Change the order to 1 for small devices */
      }
    }
    
    
    /*****Details css***/

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

/* Shadows */
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Borders */
.rounded {
  border-radius: 0.25rem;
}

.rounded-full {
  border-radius: 9999px;
}

/* Buttons */
.inline-flex {
  display: inline-flex;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-x-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.border {
  border-width: 1px;
}

.bg-white {
  background-color: #ffffff;
}

.bg-yellow-100 {
  background-color: #fefcbf;
}

.bg-green-100 {
  background-color: #c6f6d5;
}

.bg-blue-100 {
  background-color: #c7ebf2;
}

.bg-indigo-100 {
  background-color: #d4d7fb;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.bg-blue-700 {
  background-color:#022D36;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, #10b981, #3b82f6);
}

/* Text */
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-slate-900 {
  color: #111827;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-gray-600 {
  color: #718096;
}

.text-yellow-800 {
  color: #7f9c2d;
}

.text-green-800 {
  color: #047857;
}

.text-blue-800 {
  color: #185adb;
}

.text-indigo-800 {
  color: #4338ca;
}

.text-gray-800 {
  color: #4b5563;
}

.text-stone-500 {
  color: #718096;
}

.text-slate-700 {
  color: #374151;
}

.text-slate-500 {
  color: #6b7280;
}

.text-slate-600 {
  color: #4b5563;
}

.text-white {
  color: #ffffff;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

/* Images */
.object-center {
  object-position: center;
}

.object-cover {
  object-fit: cover;
}

/* Flex */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Padding and Margins */
.p-5 {
  padding: 1.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.ml-auto {
  margin-left: auto;
}

/* Width */
.w-full {
  width: 100%;
}

.md:w-1/2 {
  width: 50%;
}

.w-[90px] {
  width: 90px;
}

/* Height */
.h-[140px] {
  height: 140px;
}

.h-[90px] {
  height: 90px;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

/* Border */
.border-4 {
  border-width: 4px;
}

/* Miscellaneous */
.rounded-sm {
  border-radius: 0.375rem;
}

.self-start {
  align-self: flex-start;
}

.leading-3 {
  line-height: 0.75rem;
}

.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

/* Transitions */
.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Hover Effects */
.hover\:border-gray-300:hover {
  border-color: #d1d5db;
}

.hover\:bg-gray-100:hover {
  background-color: #f3f4f6;
}

.hover\:bg-blue-600:hover {
  background-color: #2563eb;
}

.hover\:text-white:hover {
  color: #ffffff;
}

/* Focus Effects */
.focus\:border-gray-300:focus {
  border-color: #d1d5db;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus {
  box-shadow: 0 0 0 2px rgba(163, 169, 174, 0.5);
}

.focus\:ring-blue-300:focus {
  box-shadow: 0 0 0 2px rgba(164, 202, 254, 0.5);
}

.focus\:ring-gray-300:focus {
  box-shadow: 0 0 0 2px rgba(209, 213, 219, 0.5);
}

/* Active Effects */
.active\:bg-white:active {
  background-color: #ffffff;
}

.active\:bg-blue-700:active {
  background-color: #2c5282;
}

.active\:text-white:active {
  color: #ffffff;
}

.detailer {
  justify-content: center;
  width: 800px;
  margin: 50px auto 0 auto; /* Adjust the top margin as needed */
  padding: 10px;
}
  @media only screen and (max-width: 859px) {
.detailer{

  width: 400px;
  
}
    
    
    
  }


:root {
  --c-gray-900: #000000;
  --c-gray-800: #1f1f1f;
  --c-gray-700: #2e2e2e;
  --c-gray-600: #313131;
  --c-gray-500: #969593;
  --c-gray-400: #a6a6a6;
  --c-gray-300: #bdbbb7;
  --c-gray-200: #f1f1f1;
  --c-gray-100: #ffffff;
  --c-green-500: #45ffbc;
  --c-olive-500: #e3ffa8;
  --c-white: var(--c-gray-100);
  --c-text-primary: var(--c-gray-100);
  --c-text-secondary: var(--c-gray-200);
  --c-text-tertiary: var(--c-gray-500);
}

.body {
  
 background-color: #f5f9fb;
}

img {
  display: block;
  max-width: 100%;
}

button,
select,
input,
textarea {
  font: inherit;
}

a {
  color: inherit;
}

.responsive-wrapper {
  width: 90%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.app {
  min-height: 80vh;
  width: 95%;
  max-width: 1600px;

  padding: 2vw 4vw 6vw;
  display: flex;
  flex-direction: column;
}

.app-header {
  display: grid;
  grid-template-columns: minmax(-webkit-min-content, 175px) minmax(-webkit-max-content, 1fr) minmax(-webkit-max-content, 400px);
  grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr) minmax(max-content, 400px);
  -moz-column-gap: 4rem;
       grid-column-gap: 4rem;
       column-gap: 4rem;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--c-gray-600);
  }
}

@media (max-width: 1200px) {
  .app-header-navigation {
    display: none;
  }
}

.app-header-actions {
  display: flex;
  align-items: center;
}
@media (max-width: 1200px) {
  .app-header-actions {
    display: none;
  }
}

.app-header-actions-buttons {
  display: flex;
  border-left: 1px solid var(--c-gray-600);
  margin-left: 2rem;
  padding-left: 2rem;
}
.app-header-actions-buttons > * + * {
  margin-left: 1rem;
}

.app-header-mobile {
  display: none;
}
@media (max-width: 1200px) {
  .app-header-mobile {
    display: flex;
  }
}

.app-body {
  height: 100%;
  display: grid;
  grid-template-columns: minmax(-webkit-min-content, 175px) minmax(-webkit-max-content, 1fr) minmax(-webkit-min-content, 400px);
  grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr) minmax(min-content, 400px);
  -moz-column-gap: 4rem;
       grid-column-gap: 4rem;
       column-gap: 4rem;
  padding-top: 2.5rem;
}
@media (max-width: 1200px) {
  .app-body {
    grid-template-columns: 1fr;
  }
  .app-body > * {
    margin-bottom: 3.5rem;
  }
}

.app-body-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .app-body-navigation {
    display: none;
  }
}

.footer {
  margin-top: auto;
}
.footer h1 {
  font-size: 1.5rem;
  line-height: 1.125;
  display: flex;
  align-items: flex-start;
}
.footer h1 small {
  font-size: 0.5em;
  margin-left: 0.25em;
}
.footer div {
  border-top: 1px solid var(--c-gray-600);
  margin-top: 1.5rem;
  padding-top: 1rem;
  font-size: 0.75rem;
  color: var(--c-text-tertiary);
}

.logo {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid var(--c-gray-600);
}
@media (max-width: 1200px) {
  .logo {
    border-bottom: 0;
  }
}

.logo-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.logo-title {
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  margin-left: 0.75rem;
}
.logo-title span:first-child {
  color: var(--c-text-primary);
}
.logo-title span:last-child {
  color: var(--c-text-tertiary);
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--c-text-tertiary);
}
.navigation a {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.25s ease;
}
.navigation a * {
  transition: 0.25s ease;
}
.navigation a i {
  margin-right: 0.75rem;
  font-size: 1.25em;
  flex-shrink: 0;
}
.navigation a + a {
  margin-top: 1.25rem;
}
.navigation a:hover, .navigation a:focus {
  transform: translateX(4px);
  color: var(--c-text-primary);
}

.tabs {
  display: flex;
  justify-content: space-between;
  color: var(--c-text-tertiary);
  border-bottom: 1px solid var(--c-gray-600);
}
.tabs a {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  border-top: 2px solid transparent;
  display: inline-flex;
  transition: 0.25s ease;
}
.tabs a.active, .tabs a:hover, .tabs a:focus {
  color: var(--c-text-primary);
  border-color: var(--c-text-primary);
}

.user-profile {
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  color: var(--c-text-tertiary);
  transition: 0.25s ease;
}
.user-profile:hover, .user-profile:focus {
  color: var(--c-text-primary);
}
.user-profile:hover span:last-child, .user-profile:focus span:last-child {
  box-shadow: 0 0 0 4px var(--c-gray-800);
}
.user-profile span:first-child {
  display: flex;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-gray-600);
  font-weight: 300;
}
.user-profile span:last-child {
  transition: 0.25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1.5rem;
  flex-shrink: 0;
}

.button {
  width: 32px;
  height: 32px;
  border-radius: 20%;
  border: 0;
  float: right;
  border: 1px solid var(--c-gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.25s ease;
  flex-shrink: 0;
  padding: 10px;
 
}
.icon-button.large {
  width: 42px;
  height: 42px;
  font-size: 1.25em;
}
.icon-button i {
  transition: 0.25s ease;
}
.icon-button:hover, .icon-button:focus {
  background-color: var(--c-gray-600);
  box-shadow: 0 0 0 4px var(--c-gray-800);
}

.tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 1rem;
       grid-column-gap: 1rem;
       column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1.25rem;
}
@media (max-width: 700px) {
  .tiles {
    grid-template-columns: repeat(1, 1fr);
  }
}

.tile {
  padding: 1rem;
  border-radius: 8px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: 0.25s ease;
  background-color: #ffffff; /* White background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Add padding for content */

}
.tile:hover {
  transform: translateY(-5px);
}
.tile:focus-within {
  box-shadow: 0 0 0 2px var(--c-gray-800);
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: blue;
}
.tile a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}
.tile a .icon-button {
  color: inherit;
  border-color: inherit;
}
.tile a .icon-button:hover, .tile a .icon-button:focus {
  background-color: transparent;
}
.tile a .icon-button:hover i, .tile a .icon-button:focus i {
  transform: none;
}
.tile a:focus {
  box-shadow: none;
}
.tile a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tile-header {
  display: flex;
  align-items: center;
}
.tile-header i {
  font-size: 2.5em;
}
.tile-header h3 {
  display: flex;
  flex-direction: column;
  line-height: 1.375;
  margin-left: 0.5rem;
}
.tile-header h3 span:first-child {
  font-weight: 600;
}
.tile-header h3 span:last-child {
  font-size: 0.825em;
  font-weight: 200;
}

.service-section > h2 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.service-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-section-header > * + * {
  margin-left: 1.25rem;
}


.transfer-section {
  margin-top: 2.5rem;
}

.transfer-section-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--c-gray-600);
}
.transfer-section-header h2 {
  font-size: 1.5rem;
}



.filter-options {
  margin-left: 1.25rem;
  padding-left: 1.25rem;
  border-left: 1px solid var(--c-gray-600);
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  float: right;
}
.filter-options p {
  
  font-size: 0.875rem;
}
.filter-options p + * {
  margin-left: auto;
  margin-right: 0.75rem;
}
@media (max-width: 1000px) {
  .filter-options p {
    display: none;
  }
}

.transfers {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.transfer {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
}
@media (max-width: 1000px) {
  .transfer {
    align-items: flex-start;
    flex-direction: column;
  }
}
.transfer + * {
  margin-top: 2rem;
}

.transfer-logo {
  background-color: var(--c-gray-200);
  border-radius: 4px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer-logo img {
  width: 45%;
}

.transfer-details {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
@media (max-width: 1000px) {
  .transfer-details {
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 1rem;
  }
}
.transfer-details div {
  width: calc(100% / 3 - 1rem);
}
@media (max-width: 1000px) {
  .transfer-details div {
    width: 100%;
  }
}
.transfer-details div + div {
  margin-left: 1rem;
}
@media (max-width: 1000px) {
  .transfer-details div + div {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.transfer-details dd {
  color: var(--c-text-tertiary);
  margin-top: 2px;
}

.transfer-number {
  margin-left: 2rem;
  font-size: 1.125rem;
  flex-shrink: 0;
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1000px) {
  .transfer-number {
    margin-left: 0;
    margin-top: 1.25rem;
    width: 100%;
    justify-content: flex-start;
  }
}

.payment-section > h2 {
  font-size: 1.5rem;
}

.payment-section-header {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.payment-section-header p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.payment-section-header div {
  padding-left: 1rem;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.payment-section-header div > * + * {
  margin-left: 0.5rem;
}

.card-button {
  display: flex;
  width: 50px;
  height: 34px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: transparent;
  transition: 0.25s ease;
  border-radius: 4px;
  border: 2px solid var(--c-gray-600);
  color: var(--c-text-primary);
  cursor: pointer;
}
.card-button.mastercard svg {
  max-height: 15px;
}
.card-button:focus, .card-button:hover, .card-button.active {
  color: var(--c-gray-800);
  background-color: var(--c-white);
  border-color: var(--c-white);
}

.faq {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.faq p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.faq div {
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid var(--c-gray-600);
  border-bottom: 1px solid var(--c-gray-600);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}
.faq div label {
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid var(--c-gray-600);
}
.faq div input {
  border: 0;
  background-color: transparent;
  padding: 0.25em 0;
  width: 100%;
}

.payment-section-footer {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.save-button {
  border: 1px solid currentColor;
  color: black;
  border-radius: 6px;
  padding: 0.75em 2.5em;
  background-color: transparent;
  transition: 0.25s ease;
  cursor: pointer;
}
.table-container-scrollable {
    overflow-x: auto;
    max-width: 100%;
}

.table {
    width: 100%; /* Ensure table takes up full width */
}

/* Media query for small devices */
@media screen and (max-width: 768px) {
    .table-container-scrollable {
        overflow-x: scroll; 
        max-width: 350px;
        /* Enable horizontal scrolling */
    }
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9999;
    overflow-y: auto;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    width: 400px;
    overflow-y: auto;
    max-height: calc(100%-50px) ;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #999;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content form {
    display: flex;
    flex-direction: column;
}

.modal-content label {
    margin-top: 10px;
}

.modal-content input,
.modal-content textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-content button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #0056b3;
}
